import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"
import PageTitle from "../components/PageTitle"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStateValue } from "../contextProvider/StateProvider"
import { Link } from "gatsby"
import styled from "@emotion/styled"


const Container = styled.div``;

const StyledLink = styled(Link)`
  color: white;
  background: ${props => props.color};
  display: block;
  margin: 15px auto;
  width: 175px;
  padding: 15px;
  text-align: center;
  border-radius: 25px;
  &:hover {
    opacity: 0.7;
  }
`


const NotFoundPage = () => {
  const [{ color }] = useStateValue()
  return (
    <Layout>
      <SEO title="404: Not found" />
      {/* <PageTransition
        transitionStyles={{
          entering: { opacity: "0.2" },
          entered: { opacity: "1" },
          exiting: { opacity: "0" },
        }}
        transitionTime={300}
      > */}
      <Container>
        <PageTitle frontTitle="404" coloredTitle="Page" color={color} />
        <p>Sorry, the page you were looking for couldn't be found!!</p>
        <br />
        <StyledLink to="/" color={color}>
          Go To Home Page
        </StyledLink>
      </Container>

      {/* </PageTransition> */}
    </Layout>
  )
}

export default NotFoundPage
